import { memo, ReactNode, useEffect, useMemo, useState } from 'react';

/* Store */
import useLoginStore from '@stores/login.store.ts';

/* TS */
import consts from '@/common/consts.ts';

interface Props {
	isIndexPage?: boolean;
	isLoginPage?: boolean;
	moveUrl?: string;
	children: ReactNode;
}

const InitDataLoader = memo(({ isIndexPage = false, isLoginPage = false, moveUrl, children }: Props) => {
	const { loginInfo } = useLoginStore();
	const [viewComponent, setViewComponent] = useState<ReactNode>(null);

	const isLogin = useMemo(() => {
		return (
			(loginInfo &&
				loginInfo.email &&
				loginInfo.email !== '' &&
				window.localStorage.getItem(consts.accessTokenKeyName) &&
				window.localStorage.getItem(consts.accessTokenKeyName) !== '') ||
			false
		);
	}, [loginInfo, window.localStorage.getItem(consts.accessTokenKeyName)]);

	// 로그인 정보 여부에 따른 화면 표시
	useEffect(() => {
		if (isIndexPage) {
			if (isLogin) {
				window.location.href = moveUrl || `${consts.pageInfo.mainUrl}`;
			} else {
				window.location.href = `${consts.pageInfo.loginUrl}`;
			}
		} else {
			if ((isLoginPage && !isLogin) || (!isLoginPage && isLogin)) {
				/*
					1. 로그인 페이지, 로그인이 아닌 경우 : 컴포넌트 표시
					2. 로그인 페이지가 아니면서, 로그인인 경우 : 컴포넌트 표시
				 */
				setViewComponent(children);
			} else if (isLoginPage && isLogin) {
				/*
					1. 로그인 페이지, 로그인인 경우 : URL 이동
				 */
				window.location.href = moveUrl || `${consts.pageInfo.mainUrl}`;
			} else if (!isLoginPage && !isLogin) {
				/*
					1. 로그인 페이지가 아니면서, 로그인이 아닌 경우 : 로그인 페이지 이동
				 */
				window.location.href = `${consts.pageInfo.loginUrl}`;
			}
		}

		return () => {
			setViewComponent(null);
		};
	}, [isLogin]);

	return <>{viewComponent && children}</>;
});

export default InitDataLoader;
