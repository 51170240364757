const consts = Object.freeze({
	cp: import.meta.env.VITE_BASE_PATH,
	apiUrl: `${import.meta.env.VITE_CERO_API_URL}`,

	/* Page Url */
	pageInfo: {
		mainUrl: `${import.meta.env.VITE_BASE_PATH}/action`,
		loginUrl: `${import.meta.env.VITE_BASE_PATH}/login`,
		agreementUrl: `${import.meta.env.VITE_CERO_DOMAIN}/cero/terms`,
	},

	/* API 버전 */
	apiVersion: 'v1',

	/* 언어 설정. */
	lang: `${import.meta.env.VITE_DEFAULT_LANG}`,
	loginLang: 'en', // 로그인 페이지
	langStorageKeyName: 'language',

	/* 토큰 만료 코드 목록 */
	accessTokenKeyName: 'access-token',
	accessTokenPrefix: 'Bearer',
	TOKEN_EXPRIED_STATUS_CODES: [401, 403],
});

export default consts;
