import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface LoginUser {
	profileImgUrl?: string;
	mmbrId: number;
	email: string;
	nickname: string;
}

interface LoginStoreType {
	loginInfo: LoginUser | null;
	setLoginInfo: (loginUsers: LoginUser | null) => void;
}

const useLoginStore = create(
	persist<LoginStoreType>(
		(set) => ({
			loginInfo: null,
			setLoginInfo: (newState: LoginUser | null) => {
				set({ loginInfo: newState });
			},
		}),
		{
			name: 'loginStorage',
		},
	),
);

export default useLoginStore;
